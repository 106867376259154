
import './App.css';
import Faq from './Main/Faq';
import Main from './Main/Main';

function App() {
  return (
    <>
      <Main></Main>


    </>
  );
}

export default App;
